import QdContainer from "components/Common/QdContainer"
import React, { useState, useEffect, useMemo } from "react"
import { Badge, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap"
import TemplateCard from "./TemplateCard"
import { Link, useLocation, useNavigate } from "react-router-dom"
import EmailTemplateTableContainer from "components/Common/EmailTemplateTableContainer"
import { get } from "utils/apiClient"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"



const ApptReqEmailTemplate = () => {
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


  useEffect(() => {
    fetchData()
  }, [])

  const detailsView = name => {
    navigate("/email-template/view/" + name)
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await get('email/templates')
      const jsonData = await response
      setTemplates(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const addUser = () => {
    navigate("/create-email-template")
  }

  const clone = (name) => {
    navigate("/email-template/clone/" + name)
  }

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "name",
          Header: "Name",
        },
        {
          accessor: "desc",
          Header: "Description",
        },
        {
          accessor: "cfgType",
          Header: "State",
          Cell: ({ row }) => (
            <div className="d-flex text-center">
              {row.original.cfgType == 'published' && <Badge color="success" className="mr-1">Published</Badge>}
              {row.original.cfgType == 'staged' && <Badge color="secondary" className="mr-1">Staged</Badge>}
            </div>
          ),
        },
        {
          accessor: "",
          Header: "Action",
          Cell: ({ row }) => (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="a">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#" onClick={() => detailsView(row.original.name)}>
                    <i className="mdi mdi-card-bulleted-settings-outline text-success me-1" />{" "}
                    Template Details
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => clone(row.original.name)}
                  >
                    <i className="fas fa-copy  text-primary me-1" />{" "}
                    Clone Template
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        },

      ],
      []
    )
  }
  const columns = getColumns()




  return (
    <QdContainer>
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">Email Templates</h5>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <div className="d-flex gap-2">
                <div className="text-sm">
                  <span style={{ marginRight: "20px" }}>
                    <Link to="/create-email-template" onClick={addUser}>
                      <i
                        className="mdi mdi-email-plus-outline "
                        style={{ fontSize: "large" }}
                      />
                    </Link>
                  </span>

                  <span
                    onClick={() => {
                      fetchData()
                    }}
                    className="pointer text-primary"
                  >
                    <i className="fas fa-sync-alt" />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {console.log(columns)}
          <EmailTemplateTableContainer
            columns={columns}
            data={templates}
            isGlobalFilter={true}
            customPageSize={50}
            mapDisabled={false}
            loading={loading}
            editRow={fetchData}
            editingRow={fetchData}
            refreshTable={fetchData}
            className="custom-header-css"
          />
        </CardBody>
      </Card>

    </QdContainer>
  )
}

export default ApptReqEmailTemplate
