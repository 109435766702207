import React from "react"

import classes from "./ClosableTag.scss"

const ClosableTag = props => {
  return (
    <div className="closable-tag m-2">
      <span>{props.label}</span>
      {props.showClose &&<button type="button" className="close-button" onClick={props.onClose}>
        x
      </button>}
    </div>
  )
}

export default ClosableTag
